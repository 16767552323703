<!-- 头条资讯--右侧最新资讯 -->
<template>
  <div class="headline-ltnew">
    <h6 class="h6">最新资讯</h6>
    <div class="center">
      <div class="img">
        <img src="../../assets/img/headline/ltnew.png">
      </div>
      <h6 class="h5">十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名</h6>
      <div class="list">
        <p class="p" v-for='item,index in list' :key='index'>
          <span>{{item.tit}}</span>
          <span>{{item.time}}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-ltnew',
  props: {
    msg: String
  },
  data(){
    return {
      list:[
        {tit:'十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名',time:'2019-09-25'},
        {tit:'十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名',time:'2019-09-25'},
        {tit:'十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名',time:'2019-09-25'},
        {tit:'十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名',time:'2019-09-25'},
      ]
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-ltnew {
  width: 340px;margin:0 auto;background: #fff;padding:30px 20px 20px 20px;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .center {
    margin-top:35px;zoom:1;
    .img {
      img {
        display: block;width: 340px;height: 196px;border-radius: 10px;
      }
    }
    .h5 {
      font-size: 18px;height:40px;line-height: 40px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;
    }
    .list {
      .p {
        padding-left:10px;position: relative;height: 30px;line-height: 30px;
        span {
          &:nth-child(1) {
            width: 230px;font-size: 14px;color:#555;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;display: block;float:left;
          }
          &:nth-child(2) {
            float:right;color:#999;font-size: 14px;
          }
        }
        &:after {
          content: '';display: block;position: absolute;width: 5px;height: 5px;background: #ab1d29;border-radius: 50%;left:0px;top:12px;
        }
      }
    }
  }
}
</style>
