<!-- 头条资讯--限时抢购 -->
<template>
  <div class="headline-limt">
    <h6 class="h6">限时抢购</h6>
    <div class="center">
      <div class="img">
        <img src="../../assets/img/headline/limt.png">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-limt',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-limt {
  width: 340px;margin:0 auto;background: #fff;padding:30px 20px 20px 20px;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .center {
    margin-top:35px;zoom:1;
    .img {
      img {
        display: block;width: 340px;height: 196px;border-radius: 10px;
      }
    }
  }
}
</style>
