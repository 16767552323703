<!-- 头条资讯--右侧热门标签 -->
<template>
  <div class="headline-lttip">
    <h6 class="h6">热门标签</h6>
    <div class="center">
      <button v-for='item,index in list' :style='{color:randomColor1(),borderColor:randomColor1()}' :key='index'>{{item.tit}}</button>
    </div>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'headline-lttip',
  mixins: [mixin],//混入
  props: {
    msg: String
  },
  data(){
    return {
      list:[
        {tit:'纸巾定制'},{tit:'N折手擦纸'},{tit:'广告纸巾'},{tit:'散装方巾'},{tit:'荷包纸巾'},{tit:'纸巾定制'},{tit:'大卷纸'},{tit:'手帕纸巾'},
      ]
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-lttip {
  width: 340px;margin:0 auto;background: #fff;padding:30px 20px 10px 20px;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .center {
    margin-top:35px;zoom:1;
    button {
      padding:5px 10px;border:1px solid red;background: none;border-radius: 20px;margin-right: 10px;margin-bottom:10px;
    }
  }
}
</style>
