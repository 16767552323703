<!-- 头条资讯--头部 -->
<template>
  <div class="headline-head">
    
  </div>
</template>
<script>
export default {
  name: 'headline-head',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-head {
  height: 290px;width: 100%;background: url('../../assets/img/headline/headbg.jpg') center center no-repeat;
}
</style>
