<!-- 头条资讯--相关资讯列表 -->
<template>
  <div class="headline-aboutlist">
    <h6 class="h6">相关资讯列表</h6>
    <div class="center">
      <div class="item" v-for='item,index in list' :key='index'>
        <div class="img">
          <img :src="item.url">
        </div>
        <div class="info">
          <h6 class="h5">{{item.tit}}</h6>
          <p class="p">{{item.info}}</p>
          <p class="p1">
            <span>作者：{{item.name}}</span>
            <span><svg><use xlink:href="#iconyanjing"></use></svg>{{item.see}}</span>
            <span>{{item.time}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-aboutlist',
  props: {
    msg: String
  },
  data(){
    return {
      list:[
        {url:require('../../assets/img/headline/cls.png'),tit:'十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名',info:'十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分',name:'jojo',see:297,time:'2019-09-25'},
        {url:require('../../assets/img/headline/cls1.png'),tit:'十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名',info:'十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分',name:'jojo',see:297,time:'2019-09-25'},
        {url:require('../../assets/img/headline/cls2.png'),tit:'十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名',info:'十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分',name:'jojo',see:297,time:'2019-09-25'},
        {url:require('../../assets/img/headline/cls3.png'),tit:'十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名',info:'十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分',name:'jojo',see:297,time:'2019-09-25'},
        {url:require('../../assets/img/headline/cls4.png'),tit:'十款国风气息满满的折扇品牌排名十款国风气息满满的折扇品牌排名',info:'十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分十款国风气息满满的折扇品牌排名哪一款是品牌排牌智能车机品牌好品质智能车机品牌分',name:'jojo',see:297,time:'2019-09-25'},
      ]
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-aboutlist {
  width: 760px;margin:0 auto;background: #fff;padding:30px 20px 10px 20px;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .center {
    margin-top:35px;
    .item {
      display: flex;justify-content: flex-start;border-bottom:1px solid #e7e7e7;margin-bottom:20px;padding-bottom:20px;
      .img {
        width: 260px;
        img {
          display: block;width: 260px;height: 166px;
        }
      }
      .info {
        margin-left:20px;
        .h5 {
          font-size: 18px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;width: 480px;margin-top:20px;
        }
        .p {
          color:#777;font-size: 14px;margin-top:10px;width: 480px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;
        }
        .p1 {
          color:#999;margin-top:20px;
          span {
            font-size: 14px;
            &:nth-child(2) {
              margin-left:30px;
              svg {
                fill: #999;width: 18px;height: 18px;vertical-align: -4px;margin-right: 5px;
              }
            }
            &:nth-child(3) {
              margin-left:20px;
            }
          }
        }
      }
      &:last-child {
        border-bottom:0px;padding-bottom:0px;
      }
    }
  }
}
</style>
