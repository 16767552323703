<!-- 头条资讯--右侧定制案例 -->
<template>
  <div class="headline-ltmade">
    <h6 class="h6">定制案例</h6>
    <div class="center">
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made.png">
        </div>
        <p class="p">餐饮行业</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made1.png">
        </div>
        <p class="p">美容保健行业</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made2.png">
        </div>
        <p class="p">娱乐行业</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made3.png">
        </div>
        <p class="p">教育行业</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made4.png">
        </div>
        <p class="p">零售行业</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made5.png">
        </div>
        <p class="p">服务行业</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made6.png">
        </div>
        <p class="p">金融行业</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made7.png">
        </div>
        <p class="p">其他行业</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-ltmade',
  props: {
    msg: String
  },
  data(){
    return {
      
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-ltmade {
  width: 340px;margin:0 auto;background: #fff;padding:30px 20px 0px 20px;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .center {
    margin-top:35px;zoom:1;
    .item {
      width: calc((340px - 24px)/2);margin-right: 20px;margin-bottom:20px;float:left;height: 120px;border:1px solid #dfdfdf;border-radius: 10px;
      .img {
        img {
          display: block;width: 60px;height: 60px;margin:0 auto;margin-top:15px;
        }
      }
      .p {
        text-align: center;font-size: 16px;
      }
      &:nth-child(2n +2){
        margin-right: 0px;
      }
    }
  }
}
</style>
